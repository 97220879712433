import { FormikProps } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import { Image } from "../../../../../core/utils/images";
import PageStepForm from "../../../../../organisms/page-step-form/PageStepForm";
import { Events } from "../../../../../utils/analytics";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import {
  useShippingAddress,
  UseShippingAddressFormValues,
} from "../../../application/hooks/useShippingAddress";
import ShippingAddressForm from "../../organisms/shipping-address-form/ShippingAddressForm";
import * as styles from "./ShippingAddress.module.scss";

const shippingSVG: Image = require("../../images/pages/shipping/envelope-leg.svg") as Image;

const shippingSVGSrc: string = shippingSVG.default;

const ShippingAddress = (): ReactElement => {
  const { t } = useTranslation();
  const { initialValues, validationSchema, handleSubmit } = useShippingAddress();

  useTrackPageViewed(Events.SHIPPING_VIEWED_BROWSER);

  const shippingAddressFormData = {
    initialValues,
    validationSchema,
    validateOnMount: true,
    handleSubmit,
    children: (props: FormikProps<UseShippingAddressFormValues>) => {
      return (
        <>
          <ShippingAddressForm {...props} />
          <div className={styles.shippingBanner} role={"alert"}>
            <p>{t("pg_shipping.banner")}</p>
          </div>
        </>
      );
    },
  };

  return (
    <PageStepAnimation>
      <PageStepForm
        title={t("pg_shipping.title")}
        subtitle={t("pg_shipping.subtitle")}
        image={shippingSVGSrc}
        formData={shippingAddressFormData}
        adoptionClassName={styles.shippingAddress}
      />
    </PageStepAnimation>
  );
};

export default ShippingAddress;
