import { FormikHelpers } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useCallback } from "react";
import * as Yup from "yup";

import { useZipCode } from "../../../../core/domain/hooks/useZipCode";
import { FormikConfig } from "../../../../core/utils/formik-types";
import {
  useResult,
  useUpdateResult,
} from "../../../../organisms/result/use-cases/result-use-cases";
import { trackFormChanges } from "../../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../../utils/error-utils/catch-error-handlers";

export interface UseShippingAddressFormValues {
  address: string;
  zipCode: string;
  city: string;
}

interface HandleSubmit {
  (
    values: UseShippingAddressFormValues,
    formikHelpers: FormikHelpers<UseShippingAddressFormValues>
  ): void;
}

export interface UseShippingAddress extends FormikConfig<UseShippingAddressFormValues> {
  handleSubmit: HandleSubmit;
}

export const useShippingAddress = (): UseShippingAddress => {
  const { t } = useTranslation();
  const result = useResult();
  if (!result) {
    throw new Error("Result is required");
  }

  const updateResult = useUpdateResult();
  const { initialValues: initialZipCodeValues, validationSchema: zipCodeValidationSchema } =
    useZipCode({
      country: result.country,
      initialZipCode: result.barkibu_card.shipping_zipcode ?? result.pet_parent_zip,
    });

  const initialValues = {
    address: result.barkibu_card.shipping_address ?? result.pet_parent_address,
    zipCode: initialZipCodeValues.zipCode,
    city: result.barkibu_card.shipping_city ?? result.pet_parent_city,
  };

  const validationSchema = Yup.object({
    address: Yup.string().trim().required(t("common.validation.required")),
    zipCode: zipCodeValidationSchema.fields.zipCode,
    city: Yup.string().trim().required(t("common.validation.required")),
  });

  const handleSubmit = useCallback(
    async (
      values: UseShippingAddressFormValues,
      { setSubmitting }: FormikHelpers<UseShippingAddressFormValues>
    ) => {
      if (!updateResult) {
        return;
      }

      try {
        await updateResult({
          barkibu_card_shipping_address: values.address,
          barkibu_card_shipping_zipcode: values.zipCode,
          barkibu_card_shipping_city: values.city,
        });

        trackFormChanges(
          "shipping_address_form",
          values,
          {
            address: result.pet_parent_address || result.barkibu_card.shipping_address,
            city: result.pet_parent_city || result.barkibu_card.shipping_city,
            zipCode: result.pet_parent_zip || result.barkibu_card.shipping_zipcode,
          },
          ["address", "city", "zipCode"]
        );

        void navigate(`/results/${result.uuid}/access/`);
      } catch (err) {
        handleUpdateResultErrors(err, "ShippingAddress");
        alert(t("landing.error_message"));
        setSubmitting(false);
      }
    },
    [updateResult, result, t]
  );

  return {
    initialValues,
    validationSchema,
    handleSubmit,
  };
};
