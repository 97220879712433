import { useTranslation } from "gatsby-plugin-react-i18next";
import * as Yup from "yup";

import { CountryCode } from "../../../settings/countries";
import { FormikConfig } from "../../utils/formik-types";
import { validateZipCode } from "../../utils/validators";

interface UseZipCodeParams {
  country: CountryCode;
  initialZipCode?: string;
}

interface UseZipCodeFormValues {
  zipCode: string;
}

export interface UseZipCode extends FormikConfig<UseZipCodeFormValues> {}

export const useZipCode = ({ country, initialZipCode }: UseZipCodeParams): UseZipCode => {
  const { t } = useTranslation();
  const initialValues = {
    zipCode: initialZipCode ?? "",
  };

  const validationSchema = Yup.object({
    zipCode: Yup.string()
      .trim()
      .test({
        name: "validate-zip-code",
        test(value, ctx) {
          if (!value) {
            return ctx.createError({ message: t("common.validation.required") });
          }

          if (!validateZipCode(value, country)) {
            return ctx.createError({
              message: t("common.validation.zip_code.invalid_format"),
            });
          }

          return true;
        },
      }),
  });

  return {
    initialValues,
    validationSchema,
  };
};
