import { Form } from "formik";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import Button from "../../../../../atoms/button/Button";
import TextFormControl from "../../../../../atoms/text-form-control/TextFormControl";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { getZipCodeLength } from "../../../../../utils/locale-configuration-utils";
import * as styles from "./ShippingAddressForm.module.scss";

interface ShippingAddressFormProps {
  isSubmitting: boolean;
  isValid: boolean;
}

const ShippingAddressForm = ({ isSubmitting, isValid }: ShippingAddressFormProps): ReactElement => {
  const { t } = useTranslation();
  const result = useResult();

  return (
    <>
      {result && (
        <Form className={styles.shippingAddressForm}>
          <TextFormControl
            name="address"
            label={t("common.shipping_address")}
            autoComplete="street-address"
            adoptionClassName={styles.address}
          />
          <TextFormControl
            name="zipCode"
            label={t("common.zip_code")}
            maxLength={getZipCodeLength(result.country).max}
            autoComplete="postal-code"
            adoptionClassName={styles.zipCode}
          />
          <TextFormControl
            name="city"
            label={t("common.city")}
            autoComplete="address-level2"
            adoptionClassName={styles.city}
          />
          <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
            {t("common.cta.next")}
          </Button>
        </Form>
      )}
    </>
  );
};

export default ShippingAddressForm;
